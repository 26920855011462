// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-consultations-js": () => import("./../src/pages/consultations.js" /* webpackChunkName: "component---src-pages-consultations-js" */),
  "component---src-pages-downloads-js": () => import("./../src/pages/downloads.js" /* webpackChunkName: "component---src-pages-downloads-js" */),
  "component---src-pages-gifts-js": () => import("./../src/pages/gifts.js" /* webpackChunkName: "component---src-pages-gifts-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mixtroidvania-js": () => import("./../src/pages/mixtroidvania.js" /* webpackChunkName: "component---src-pages-mixtroidvania-js" */),
  "component---src-pages-resources-js": () => import("./../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-supersnowtubes-js": () => import("./../src/pages/supersnowtubes.js" /* webpackChunkName: "component---src-pages-supersnowtubes-js" */),
  "component---src-pages-tags-js": () => import("./../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-templates-blog-post-template-js": () => import("./../src/templates/blog-post-template.js" /* webpackChunkName: "component---src-templates-blog-post-template-js" */),
  "component---src-templates-tag-index-template-js": () => import("./../src/templates/tag-index-template.js" /* webpackChunkName: "component---src-templates-tag-index-template-js" */)
}

