/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// Custom typefaces for fonts
import "typeface-montserrat"
//import "typeface-merriweather"
import "typeface-roboto"
import "typeface-nunito"

// Prism themes for syntax highlighting
//import "prismjs/themes/prism.css"
import "prismjs/themes/prism-okaidia.css"
import "./src/styles/prism-custom.css"

// Styles
import "./src/styles/styles.scss"

// Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faYoutube,
    faTwitter,
    faFacebook,
    faInstagram,
    faPinterest,
    faGithub,
    faPatreon,
    faRedditAlien
 } from '@fortawesome/free-brands-svg-icons'
import { 
    faRss,
    faEnvelope,
    faPaste,
    faCaretLeft,
    faCaretRight,
} from '@fortawesome/free-solid-svg-icons'

// Font Awesome
// Add individual icons to the below library,
// then use them in React component files like this:
//
//  // FontAwesome
//  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//
//  <FontAwesomeIcon icon={["fab", "instagram"]} />
//
library.add(
    // fab
    faYoutube,
    faTwitter,
    faFacebook,
    faInstagram,
    faPinterest,
    faGithub,
    faPatreon,
    faRedditAlien,
    // fas
    faRss,
    faEnvelope,
    faPaste, 
    faCaretLeft,
    faCaretRight,       
);
